<script type="ts">

const RenderContext = function (h, context, scope) {
  if (IsLeaf(scope)) { 
    return RenderLeaf(h, context, scope, 0);
  }
  return RenderFolder(h, context, scope, 0);    
}

const RenderLeaf = function (h, context, scope, level) {
  return h(
    'span',
    {
      style: {
        paddingLeft: RenderIndent(scope, level)
      }
    },
    [scope.row[context.props.prop]]
  );
}

const RenderFolder = function (h, context, scope, level) {
  return h(
    'span',
    {
      style: {
        paddingLeft: RenderIndent(scope, level)
      }
    },
    [RenderExpandButton(h, context, scope, level), " ", scope.row[context.props.prop]]
  );
}

const RenderExpandButton = function (h, context, scope, level) {
  let iconCls = "el-tree-node__expand-icon el-icon-caret-right";
  if (IsExpanded(scope)) {
    iconCls += " expanded";    
  }
  return h(
    'span',
    {
      class: iconCls,
      on: {
        click: () => {
          SetExpanded(scope, level);
        }
      }
    }
  );
}

const IsLeaf = function (scope) {
  return scope.row.leaf === true || !scope.row.hasOwnProperty('children');
}

const IsExpanded = function (scope) {
  return scope.row.expanded === true;
}

const IsLoaded = function (scope) {
  return scope.row.loaded === true || scope.row.children.length;
}

const SetExpanded = function (scope, level) {
  scope.row.expanded = !scope.row.expanded;
  if (scope.row.expanded && IsLoaded(scope)) {
    RenderChildren(scope, level);
  } else if (scope.row.expanded && !IsLoaded(scope)) {
    //TODO
  } else {
    HideChildren(scope);
  }
}

const RenderChildren = function (scope, level) {
  let children = scope.row.children;
  children.forEach((child) => {
    child.level = level + 1;
  });
  scope.store.states.data.splice(scope.$index + 1, 0, ...children);
}

const HideChildren = function(scope) {
  scope.store.states.data.splice(scope.$index + 1, scope.row.children.length);
}

const RenderIndent = function (scope, level) {
  const size = scope.row.hasOwnProperty('level') ? scope.row.level : level;
  return (20  * size) + "px";
}

export default {
  name: 'TreeColumn',
  functional: true,
  render(h, context) {
    let attr = {};
    Object.keys(context.props).map(k => {
      attr[k] = context.props[k]
    })
    let attrs = { attrs: attr };
    return <el-table-column {...attrs}
      scopedSlots={{ default: (scope) => RenderContext(h, context, scope) }}>
    </el-table-column>
  }
}

</script>